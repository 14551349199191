var campaign = (function (c, d, $) {

  var body,
      form,
      form_id,
      config,
      successMessage;

  c.init = function () {

    body = $('body');
    form = $('#subForm');
    form_id = form.attr('data-id');
    successMessage = $('#success');
    successMessage.hide();

    // On form submit.
    form.submit(function (evt) {
      // Disable default form submit.
      evt.preventDefault();

      // Get e-mail value.
      email = $('input[type=email]', form).val();

      // Build request data for tokenRequest.
      request_data = "email=" + encodeURIComponent(email) + "&data=" + form_id;

      // Prepare tokenRequest.
      tokenRequest = new XMLHttpRequest();
      tokenRequest.open('POST', 'https://createsend.com//t/getsecuresubscribelink', true);
      tokenRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      tokenRequest.send(request_data);

      // Ready state.
      tokenRequest.onreadystatechange = function() {
        if (this.readyState === 4) {
          if (this.status === 200) {
            // Having token and new submit url we can create new request to subscribe a user.
            subscribeRequest = new XMLHttpRequest();
            subscribeRequest.open('POST', this.responseText, true);
            subscribeRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            subscribeRequest.send(form.serialize());
            // On ready state call response function.
            subscribeRequest.onreadystatechange = function() {
              c.response(subscribeRequest);
            }
          } else {
            c.response(tokenRequest);
          }
        }
      }
    });
  };

  // Handle ajax response.
  c.response = function(request) {
    if (request.readyState === 4) {
      if (request.status === 200) {
        successMessage.show('slow');
        form.hide();
        //alert('Tak - du er nu tilmeldt nyhedsbrevet!');
      } else {
        form.prepend('<p class="error">' + config.errorMessage + '</p>');
      }
    }
  };

  // Private
  config = {
    formSelector: 'form',
    errorMessage: 'There was a problem submitting this form. Please try later.',
    successSelector: 'success',
  };

  return c;
  
}(campaign || {}, {}, jQuery));

(function () {
  campaign.init();
})(jQuery);