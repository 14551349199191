var $ = jQuery.noConflict();
$(document).ready(function () {

	//Contact Box
	$('.open-ct').on('click', function () {
		$('#contactbox').addClass('active');
	});
	$('.close-ct').on('click', function () {
		$('#contactbox').removeClass('active');
	});
	$('.bookmeeting').on('click', function () {
		$('#contactbox').addClass('active');
	});

	//SetCookie
	$('.newsletter-box .fa').on('click', function () {
		setCookie('hidenewsletter', true, 7);
		$('.newsletter-box').addClass('notloaded');
	});

	//Cookie Helper functions
	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	//Menu toggler
	$('.menu-toggler svg').on('click', function () {
		$('nav').slideToggle();
	});

	//Extra element on parent li in navigation
	$('.menu-item-has-children').each(function () {
		$(this).append('<span class="visible-xs visible-sm toggle-down"><i class="fa fa-angle-down" aria-hidden="true"></i></span>');
	});
	$('.toggle-down').on('click', function () {
		$(this).siblings('.sub-menu').slideToggle();
		$(this).children('.fa').toggleClass('fa-angle-down');
		$(this).children('.fa').toggleClass('fa-angle-up');
	});

	//Accordion
	$('.accordion').each(function () {
		$(this).find('.accordion-toggle').click(function () {
			//Expand or collapse this panel
			$(this).next().slideToggle('fast');
			$(this).toggleClass('active');
			//Hide the other panels
			$(".accordion-content").not($(this).next()).slideUp('fast');
			$(".accordion-toggle").not($(this)).removeClass('active');
		});
	});

	//Make navigation fixed when scroll down
	var scroll = $(window).scrollTop();
	if (scroll >= 10) {
		$("header").addClass('scrolled');
	} else {
		$("header").removeClass('scrolled');
	}
	$(window).scroll(function () {
		var scroll = $(window).scrollTop();
		if (scroll >= 10) {
			$("header").addClass('scrolled');
		} else {
			$("header").removeClass('scrolled');
		}
	});

	//Lazy loading images
	$('.lazy').Lazy({
		scrollDirection: 'vertical',
		effect: 'fadeIn',
		visibleOnly: true,
	});

	//Filter
	$('.filter').on('click', function () {
		if ($(this).hasClass('active')) {
			$('.filter.active').removeClass('active');
			$('.worker-item').show();
		}
		else {
			$('.filter.active').removeClass('active');
			$(this).addClass('active');
			var office = $(this).attr('data-val');
			$('.worker-item').not('.' + office).hide();
			$('.worker-item.' + office).show();
		}
		//Hide Øvrige title if none visible
		if ($('#ovrige-items .worker-item:visible').length == 0) {
			$('#ovrige-title').hide();
		}
		else {
			$('#ovrige-title').show();
		}
		//Hide Medarbejdere title if none visible
		if ($('#medarbejdere-items .worker-item:visible').length == 0) {
			$('#medarbejdere-title').hide();
		}
		else {
			$('#medarbejdere-title').show();
		}
		//Hide Partners title if none visible
		if ($('#partners-items .worker-item:visible').length == 0) {
			$('#partners-title').hide();
		}
		else {
			$('#partners-title').show();
		}
	});

	$('#menu-hovedmenu > .menu-item-has-children > a').each(function () {
		$(this).append('<i class="fa fa-caret-down hidden-xs hidden-sm"></i>');
	});

	//Frontpage News tabs
	$('.tab-holder .tab').on('click', function () {
		if (!$(this).hasClass('active')) {
			$('.tab-holder .tab.active').removeClass('active');
			$(this).addClass('active');
			var tabID = $(this).attr('data-tab');
			$('.tab-content .wrap.active').removeClass('active');
			$('.tab-content .wrap[data-tab=' + tabID + ']').addClass('active');
		}
	});

	var sliderCounter = 1;

	$('.posts-list').each(function () {
		// Create unique IDs for the slider and its navigation
		var sliderId = 'slider-' + sliderCounter;
		var arrowsId = 'slider-arrows-' + sliderCounter;

		// Assign the ID to the current slider
		$(this).attr('id', sliderId);

		// Find the closest category__arrows and assign the unique ID
		var arrowsContainer = $(this).closest('.category-block').find('.category__arrows');
		arrowsContainer.attr('id', arrowsId);

		// Initialize the slider with the new IDs for appendArrows
		$('#' + sliderId).slick({
			// Slick Slider options
			infinite: true,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: true,
			appendArrows: $('#' + arrowsId), // Correctly target the specific arrows container
			prevArrow: '<div class="category__arrow category__arrow_dir_left"><svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 19.5L8.25 12L15.75 4.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></div>',
			nextArrow: '<div class="category__arrow category__arrow_dir_right"><svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.25 4.5L15.75 12L8.25 19.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></div>',
			dots: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						infinite: true,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		// Increment the counter for the next slider
		sliderCounter++;
	});

});


